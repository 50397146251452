import classNames from 'classnames';
import React from 'react';
import desktopImage from '../../images/blog/cta-ui-desktop.svg';
import mobileImage from '../../images/blog/cta-ui-mobile.svg';
import howSuccessIsWrittenImage from '../../images/blog/how-success-is-written-cta.png';
import libraryToggle from '../../images/library/library.svg';
import libraryAmazonTemplate from '../../images/blog/library-template-amazon.svg';
import libraryAirbnbTemplate from '../../images/blog/library-template-airbnb.svg';
import Button from '../ui/Button';
import Link from '../ui/Link';
import styles from './BlogCTA.module.scss';

type BlogCTAProps = {
  anchorClassName?: string;
  type?: 'how-success-is-written' | 'knowledge-hub' | 'library';
};

const BlogCTA = ({ anchorClassName, type = 'knowledge-hub' }: BlogCTAProps) => {
  if (type === 'knowledge-hub') {
    return (
      <Link
        to="/"
        className={classNames(styles.container, anchorClassName)}
        track={{ label: 'blog-inline-cta' }}
      >
        <picture>
          <source media="(max-width: 480px)" srcSet={mobileImage} />
          <source srcSet={desktopImage} />
          <img
            loading="lazy"
            decoding="async"
            src={desktopImage}
            alt="Get Started with Slab"
            width="100%"
            height="100%"
          />
        </picture>
      </Link>
    );
  }
  if (type === 'how-success-is-written') {
    return (
      <Link
        to="/guides/how-success-is-written"
        className={classNames(styles.howSuccessIsWritten, anchorClassName)}
        track={{ label: 'how-success-is-written-blog-cta' }}
      >
        <div className={styles.copy}>
          <div className={styles.wrap}>
            <h2 className={styles.heading}>How Success is Written</h2>
            <p>Learn how top companies are using writing to fuel innovation</p>
            <Button primary small>
              Download eBook
            </Button>
          </div>
        </div>
        <img
          src={howSuccessIsWrittenImage}
          alt="A sketchbook of various company logos"
        />
      </Link>
    );
  }

  if (type === 'library') {
    return (
      <Link
        to="/library/"
        className={classNames(styles.library, anchorClassName)}
        track={{ label: 'library-blog-cta' }}
      >
        <div>
          <img src={libraryToggle} className={styles.libraryToggle} alt="" />
          <h1>Slab Library</h1>
          <p>Discover templates and examples from leaders in the industry</p>
          <Button small secondary>
            Browse library
          </Button>
        </div>
        <div>
          <div
            className={styles.libraryAmazon}
            style={{ backgroundImage: `url("${libraryAmazonTemplate}")` }}
          />
          <div
            className={styles.libraryAirbnb}
            style={{ backgroundImage: `url("${libraryAirbnbTemplate}")` }}
          />
        </div>
      </Link>
    );
  }

  return null;
};

export default BlogCTA;
