import React, { useState } from 'react';
import styles from './LibraryPostWindow.module.scss';
import Button from '../ui/Button';
import getTemplateUrl from './getTemplateUrl';
import browserNavigationIcon from '../../images/library/browserNavigation.svg';
import SignUpModal from '../signUpModal/SignUpModal';
import useOpenTemplate from './useOpenTemplate';

interface LibraryPostWindowProps {
  slug: string;
}
const LibraryPostWindow = ({ slug }: LibraryPostWindowProps) => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);

  const openTemplate = useOpenTemplate(slug, () => setIsSignUpModalOpen(true));

  return (
    <>
      <div className={styles.template}>
        <div className={styles.header}>
          <div className={styles.navigationIcon}>
            <img
              height={16}
              src={browserNavigationIcon}
              alt=""
              role="presentation"
            />
          </div>
          <div className={styles.templateButton}>
            <Button unstyled onClick={openTemplate}>
              Use template
            </Button>
          </div>
        </div>
        <iframe src={`/library/embed/${slug}`} title="Slab Library template" />
      </div>
      {isSignUpModalOpen && (
        <SignUpModal
          onClose={() => setIsSignUpModalOpen(false)}
          loginUrl={getTemplateUrl(slug)}
        />
      )}
    </>
  );
};

export default LibraryPostWindow;
