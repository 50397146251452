import classNames from 'classnames';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { useCallback, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import RehypeReact from 'rehype-react';
import BlogCTA from '../components/blog/BlogCTA';
import BlogGrid from '../components/blog/BlogGrid';
import BlogPostHeader from '../components/blog/BlogPostHeader';
import BlogTOC from '../components/blog/BlogTOC';
import FloatingSubscribe from '../components/blog/FloatingSubscribe';
import SidebarCTA from '../components/blog/SidebarCTA';
import useAllPosts from '../components/blog/useAllPosts';
import BlogLayout from '../components/layout/BlogLayout';
import Meta from '../components/layout/Meta';
import HR from '../components/ui/HR';
import Quote from '../components/ui/Quote';
import LibraryPostWindow from '../components/library/LibraryPostWindow';
import { BlogPost } from '../types';
import { blogSubscribedCookie } from '../utils/Cookies';
import { fullPost, PostQueryResponse } from '../utils/fullPosts';
import { blogCategoryPath } from '../utils/path';
import styles from './BlogTemplate.module.scss';

interface BlogTemplateProps {
  post: BlogPost;
}

const BlogCTAInBlogTemplate = (props: object) => (
  <BlogCTA anchorClassName={styles.blogCTA} {...props} />
);

const getRenderer = (slabComponentProps: object) => {
  return new RehypeReact({
    createElement: (
      component: React.ElementType,
      props: object,
      children: React.ElementType,
    ) =>
      React.createElement(
        component,
        typeof component === 'string'
          ? props
          : { ...slabComponentProps, ...props },
        children,
      ),
    components: {
      'slab-cta': BlogCTAInBlogTemplate,
      'slab-quote': Quote,
      'library-template': LibraryPostWindow,
    },
  }).Compiler;
};

const isOverlapping = (rectA: DOMRect, rectB: DOMRect) =>
  !(
    rectB.left >= rectA.right ||
    rectB.right <= rectA.left ||
    rectB.top >= rectA.bottom ||
    rectB.bottom <= rectA.top
  );

const BlogTemplate: React.SFC<BlogTemplateProps> = ({ post }) => {
  const {
    htmlAst,
    excerpt,
    title,
    date,
    edited,
    author,
    category,
    coverFluid,
    gradient,
    slug,
    summary,
    citations,
  } = post;

  const [contentNode, setContentNode] = useState<HTMLDivElement | null>();
  const [sidebarNode, setSidebarNode] = useState<HTMLDivElement | null>();
  const [isHidingSidebar, setIsHidingSidebar] = useState(true);
  const [subscribeTopOffset, setSubscribeTopOffset] = useState<number>();
  const [isShowingSubscribe, setIsShowingSubscribe] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(
    blogSubscribedCookie.get() === '1',
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sidebarRef = useCallback(setSidebarNode, []);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contentRef = useCallback(setContentNode, []);

  const calculateTopOffset = () => {
    if (contentNode) {
      setSubscribeTopOffset(contentNode.clientHeight * -0.4);
    }
  };

  useEffect(() => {
    calculateTopOffset();
    window.addEventListener('resize', calculateTopOffset, { passive: true });

    return () => window.removeEventListener('resize', calculateTopOffset);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentNode]);

  useEffect(() => {
    if (!contentNode || !sidebarNode) {
      return undefined;
    }

    const hideTOCOnOverlap = () => {
      const tocRect = sidebarNode.getBoundingClientRect();
      const imageElements: Element[] = Array.from(
        contentNode.getElementsByClassName('gatsby-resp-image-image'),
      );

      const shouldHide = !!imageElements.find((imageElement) => {
        const imageRect = imageElement.getBoundingClientRect();

        return isOverlapping(tocRect, imageRect);
      });

      setIsHidingSidebar(shouldHide);
    };

    document.addEventListener('scroll', hideTOCOnOverlap);

    const mutationObserver = new MutationObserver(hideTOCOnOverlap);
    mutationObserver.observe(sidebarNode, { subtree: true, childList: true });

    return () => {
      document.removeEventListener('scroll', hideTOCOnOverlap);
      mutationObserver.disconnect();
    };
  }, [contentNode, sidebarNode]);

  const metaTitle = [title, 'Knock Down Silos by Slab']
    .filter((el) => el)
    .join(' - ');

  const allPosts = useAllPosts();
  const categoryPosts = allPosts
    .filter((p) => p.category === category && p.slug !== slug)
    .slice(0, 3);

  const canShowFloatingSubscribe = !isSubscribed && subscribeTopOffset != null;

  const localizeDate = (dateString?: string) =>
    dateString
      ? new Date(dateString).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          timeZone: 'UTC',
          year: 'numeric',
        })
      : '';

  const publishDate = localizeDate(date);
  const editedDate = localizeDate(edited);

  return (
    <BlogLayout>
      <Meta
        title={metaTitle}
        description={summary || excerpt}
        image={coverFluid?.src}
        openGraphType="article"
        canonicalPath={post.canonicalPath}
      />
      <div className={styles.container}>
        {!gradient && post.coverFluid && (
          <div className={styles.coverContainer}>
            <GatsbyImage
              fluid={post.coverFluid}
              fadeIn={false}
              loading="eager"
            />
          </div>
        )}
        <div className={styles.postContainer}>
          <div className={styles.post}>
            <BlogPostHeader
              category={category}
              title={title}
              summary={summary}
              author={author}
              publishDate={publishDate}
              editedDate={editedDate}
            />
            <div className={styles.content} ref={contentRef}>
              {getRenderer({ citations })(htmlAst)}
            </div>
            {canShowFloatingSubscribe && (
              <Waypoint
                topOffset={subscribeTopOffset}
                onPositionChange={({ currentPosition }) => {
                  setIsShowingSubscribe(currentPosition !== Waypoint.below);
                }}
              />
            )}
          </div>
          <div
            className={classNames(styles.sidebar, {
              [styles.hide]: isHidingSidebar,
            })}
            ref={sidebarRef}
          >
            {contentNode && <BlogTOC container={contentNode} />}
            <div className={styles.sidebarFooter}>
              <HR />
              <SidebarCTA category={category} />
            </div>
          </div>
        </div>
        {categoryPosts.length > 0 && (
          <div className={styles.footer}>
            <h2 className={styles.header}>
              Continue Reading
              <span className={styles.muted}>
                {' other '}
                <Link to={blogCategoryPath(category)}>{category}</Link> articles
              </span>
            </h2>
            <BlogGrid posts={categoryPosts} />
          </div>
        )}
        {canShowFloatingSubscribe && (
          <Waypoint
            onEnter={() => {
              setIsShowingSubscribe(false);
            }}
          />
        )}
      </div>
      {/* Need to be the last item so React is not confused with null -> portal switch */}
      <FloatingSubscribe
        visible={!isSubscribed && isShowingSubscribe}
        onSubscribed={() => {
          setTimeout(() => setIsSubscribed(true), 3000);
        }}
      />
    </BlogLayout>
  );
};

export default (response: PostQueryResponse) => (
  <BlogTemplate post={fullPost(response)} />
);

export const query = graphql`
  query ($slug: String!) {
    post: markdownRemark(
      fields: { slug: { eq: $slug }, type: { eq: "blog" } }
    ) {
      html
      htmlAst
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        date
        edited
        author
        category
        cover {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        gradient
        summary
        citations {
          id
          name
          title
          link
          quoteColor
          picture {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        canonicalPath
      }
    }
    ...AuthorsQueryFragment
  }
`;
